const ADDRESS = [
  {
    brand_number: "Chi Nhánh 1:",
    address: "31 Nguyễn An Ninh, khu phố Nhị Đồng 2, Dĩ An, Bình Dương",
    phone_number: "0343.159.159",
    google_address: "https://maps.app.goo.gl/DtA8xHK5enz8KgEC7",
    google_map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d489.7375349662803!2d106.76670446991919!3d10.895182787955239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d983a882b79d%3A0x5d3ac2ea58a6b011!2sT%C3%A1o%20Xanh%20Apple!5e0!3m2!1sen!2sus!4v1733988861272!5m2!1sen!2suss",
  },
  {
    brand_number: "Chi Nhánh 2:",
    address: "284 Nguyễn Tri Phương, KP4, An Bình, Dĩ An, Bình Dương",
    phone_number: "0973.159.579",
    google_address: "https://maps.app.goo.gl/AFkc51oniFjYExZZA",
    google_map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.987703439314!2d106.75691937355363!3d10.888538657118797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d865f1ba14b9%3A0xeb9e833e435d9a69!2zMjg0IMSQLiBOZ3V54buFbiBUcmkgUGjGsMahbmcsIEFuIELDrG5oLCBExKkgQW4sIELDrG5oIETGsMahbmcsIFZpZXRuYW0!5e0!3m2!1sen!2sus!4v1733989182066!5m2!1sen!2sus",
  },
  {
    brand_number: "Chi Nhánh 3:",
    address: "1261 Tỉnh lộ 43, Bình Chiểu, Thành Phố Thủ Đức, TP.HCM",
    phone_number: "0973.711.789",
    google_address: "https://maps.app.goo.gl/aLp4YVtadc9vC9M89",
    google_map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1385.2056277674317!2d106.72383831568543!3d10.891163578341125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d81e0acb1acb%3A0x6521b5702f37d4bf!2zMTI2MSBUTDQzLCBCw6xuaCBDaGnhu4N1LCBUaOG7pyDEkOG7qWMsIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1733983447054!5m2!1sen!2s",
  },
  {
    brand_number: "Chi Nhánh 4:",
    address: "297 Lý Thường Kiệt, Dĩ An, Bình Dương",
    phone_number: "0973.711.789",
    google_address: "https://maps.app.goo.gl/1GaFpqzS9yzye5vD7",
    google_map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.732867049466!2d106.75373377355392!3d10.907894856758038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d84fa5e445a3%3A0xb7b70e5c1e89e197!2zMjk3IMSQLiBMw70gVGjGsOG7nW5nIEtp4buHdCwgRMSpIEFuLCBCw6xuaCBExrDGoW5nLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1733983554076!5m2!1sen!2s",
  },
];

export { ADDRESS };
