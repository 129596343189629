import { Box, Link, Typography } from "@mui/material";
import React from "react";
import Address from "../components/footer/Address";
import Policy from "../components/footer/Policy";
import IconContact from "../components/footer/IconContact";

const styles = {
  boxWrap: {
    backgroundColor: "rgb(255,255,255)",

    color: "primary.contrastText",
    display: "flex",
    flexDirection: { md: "row", xs: "column" },
    justifyContent: { md: "space-evenly", xs: "space-between" },
    paddingTop: 3,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    boxShadow: 15,
    overflow: "hidden",
  },

  boxFooter: {
    backgroundColor: "#151618",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

function MainFooter() {
  return (
    <>
      <Box name="boxWrap" sx={styles.boxWrap}>
        <Address />

        <Policy />

        <IconContact />
      </Box>

      <Box sx={styles.boxFooter}>
        <Typography variant="subtitle2" color="primary.footerTypo" p={1}>
          {"Copyright  ©"}{" "}
        </Typography>
        <Link
          color="inherit"
          href="https://www.facebook.com/TaoXanhStores"
          underline="none"
        >
          <Typography color="primary.footerLink">taoxanhstore</Typography>
        </Link>

        <Typography variant="subtitle2" color="primary.footerTypo">
          {". "} {new Date().getFullYear()}
        </Typography>
      </Box>
    </>
  );
}

export default MainFooter;
