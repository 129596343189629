import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";

const PRIMARY = {
  lighter: "#9e9e9e",
  light: "#757575",
  main: "#616161",
  dark: "#757575",
  darker: "#212121",
  contrastText: "#383838",
  footerLink: "#00e676",
  footerTypo: "#898989",
};
const SECONDARY = {
  lighter: "#FFD07F",
  light: "#FDA65D",
  main: "#FF8243",
  dark: "#E26A2C",
  darker: "#cc571f",
  contrastText: "#FFF",
  title: "rgb(33, 43, 54)",
};
const SUCCESS = {
  lighter: "#e8f5e9",
  light: "#c8e6c9",
  main: "#4caf50",
  dark: "#81c784",
  darker: "#66bb6a",
  contrastText: "#FFF",
};
const INFO = {
  main: "#FFF",
};
const ICON = {
  main: "#f00",
};
const TEXT_FIELD = {
  main: "rgb(19, 128, 28)",
};

function ThemeProvider({ children }) {
  const themeOptions = {
    palette: {
      primary: PRIMARY,
      secondary: SECONDARY,
      success: SUCCESS,
      info: INFO,
      icon: ICON,
      textField: TEXT_FIELD,
    },
    shape: { borderRadius: 8 },
    typography: {
      fontFamily: "Poppins, sans-serif",

      subtitle2: {
        color: "rgb(18,18,18)",
      },
      subtitle1: {
        color: "rgb(33, 43, 54)",
      },
      subtitle3: {
        color: "primary.contrastText",
      },
      subtitle4: {
        color: "icon.main",
      },
    },
  };

  const theme = createTheme(themeOptions);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

export default ThemeProvider;
