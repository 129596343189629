import { Box, Link, Typography } from "@mui/material";
import React from "react";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

import { ADDRESS } from "../data";

const styles = {
  wrapInfo: {
    width: { sm: 550 },
    p: 1,
  },
  typo: {
    fontSize: { sm: "1.5rem", xs: 12 },
    fontWeight: 500,
    textAlign: "center",
  },

  boxWrapIcon: {
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    mb: 3,
    justifyContent: "center",
    alignItems: "center",
  },

  link: {
    textDecoration: "none",
    color: "primary.contrastText",
  },

  boxWrapTypo: { boxShadow: 2, borderRadius: 2, p: 1, mb: 3, mt: 10 },

  typoPhone: {
    display: "flex",
    alignItems: "center",
    mt: 1,
  },
};

function DiachiPage() {
  return (
    <Box sx={{ textAlign: "justify", mt: 5, p: 4 }}>
      <Typography mb={2} textAlign="center" fontSize={36} fontWeight={600}>
        Hệ thống chi nhánh cửa hàng
      </Typography>

      {ADDRESS.map((el, index) => (
        <Box sx={styles.boxWrapTypo} key={index}>
          <Typography sx={styles.typo}>{el.brand_number}</Typography>
          <Box sx={styles.boxWrapIcon}>
            <Typography>- {el.address}</Typography>
            <Typography sx={styles.typoPhone}>
              <PhoneInTalkIcon sx={styles.icon} fontSize="small" />
              <Link href={`tel: ${el.phone_number}`} sx={styles.link}>
                :{el.phone_number}
              </Link>
            </Typography>
          </Box>

          <Box
            sx={{
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              title={el.brand_number}
              src={`${el.google_map}`}
              width="100%"
              height="100%"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default DiachiPage;
