import { Box, Link, Typography } from "@mui/material";
import React from "react";

import footerImg from "../../images/taoxanh-logo.jpeg";
import { ADDRESS } from "../../data";

const styles = {
  wrap: {
    width: { md: 300, xs: "100%" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "start",
    pl: { md: 0, lg: 0, sm: 1, xs: 1 },
    pr: { md: 0, lg: 0, sm: 1, xs: 1 },
    gap: 2,
  },
  typo: {
    fontSize: { sm: 13, xs: 12 },
    color: "rgb(99,110,114)",
    mb: 1,
  },

  boxImg: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  link: {
    textDecoration: "none",
  },
};

function Address() {
  return (
    <Box name="wrap" sx={styles.wrap}>
      <Box sx={styles.boxImg}>
        <Box
          component="img"
          src={footerImg}
          alt="footerimg"
          width={{ md: 200, xs: "50%", sm: 400 }}
        />
      </Box>

      <Box name="address">
        <Typography variant="subtitle1">Địa chỉ:</Typography>
        {ADDRESS.map((el, index) => (
          <Typography variant="subtitle2" sx={styles.typo} key={index}>
            CN{index + 1}:{" "}
            <Link
              target="_blank"
              href={`${el.google_address}`}
              sx={styles.link}
            >
              {el.address}
            </Link>
          </Typography>
        ))}
      </Box>

      <Box name="hotline" width="100%">
        <Typography variant="subtitle1">
          Hotline:{" "}
          <Link
            href="tel: 0274.999.66.77"
            sx={{ textDecoration: "none", color: "primary.darker" }}
          >
            0274.999.66.77
          </Link>
        </Typography>
        {ADDRESS.map((el, index) => (
          <Typography variant="subtitle2" sx={styles.typo} key={index}>
            CN{index + 1}:{" "}
            <Link href={`tel: ${el.phone_number}`} sx={styles.link}>
              :{el.phone_number}
            </Link>
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

export default Address;
